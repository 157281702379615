<template>
  <S2SForm title="Paid Orders">
    <!-- Toolbar Buttons -->
    <v-btn icon slot="toolbar-content" :to="{ name: 'search-order' }">
      <v-icon>search</v-icon>
    </v-btn>

    <v-layout row wrap pb-2 :justify-end="$vuetify.breakpoint.mdAndUp">
      <v-flex md2 mx-1>
        <v-text-field
          @click="modal = true"
          label="From Date"
          prepend-icon="event"
          v-model="dates.fromDate"
          readonly
          hide-details
          clearable
        ></v-text-field>
      </v-flex>
      <v-flex md2 mx-1>
        <v-text-field
          @click="modal = true"
          label="To Date"
          prepend-icon="event"
          :value="dates.toDate"
          readonly
          hide-details
          clearable
        ></v-text-field>
      </v-flex>
      <v-flex md2 mx-1 d-flex>
        <v-select
          :items="orderStatussesPaid"
          label="Status"
          v-model="statusFilters"
          item-text="name"
          item-value="id"
          multiple
        >
          <v-list-item
            v-show="statusFilters.length > 0"
            slot="prepend-item"
            ripple
            @click.stop="statusFilters = []"
          >
            <v-list-item-title>{{ "Clear Filter" }}</v-list-item-title>
          </v-list-item>
          <v-divider
            v-show="statusFilters.length > 0"
            slot="prepend-item"
            class="mt-2"
          ></v-divider>
        </v-select>
      </v-flex>
    </v-layout>
    <v-card>
      <v-dialog ref="dialog" v-model="modal" persistent width="initial">
        <v-container class="white">
          <v-layout pb-2>
            <v-flex>
              <h4 style="background-color: white" class="py-2 pl-2">
                From Date: {{ dates.fromDate }}
              </h4>
              <v-date-picker
                v-model="dates.fromDate"
                type="date"
                scrollable
                no-title
                :max="dates.toDate"
              ></v-date-picker>
            </v-flex>
            <v-flex>
              <h4 style="background-color: white" class="py-2 pl-2">
                To Date: {{ dates.toDate }}
              </h4>
              <v-date-picker
                v-model="dates.toDate"
                type="date"
                scrollable
                no-title
                :min="dates.fromDate"
              ></v-date-picker>
            </v-flex>
          </v-layout>

          <v-flex>
            <v-btn
              outlined
              color="primary"
              @click="modal = false"
              block
              min-height="100%"
              >Done</v-btn
            >
          </v-flex>
        </v-container>
      </v-dialog>

      <v-data-table
        ref="dataTable"
        :headers="headers"
        :items="orders"
        :options.sync="_pagination"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1"
        @click:row="onOrder($event)"
      >
        <template v-slot:item.user="{ item }">
          <v-flex v-if="item.customer">{{ item.customer.name }}</v-flex>
          <v-flex v-else
            >{{ item.user.profile.firstName }}
            {{ item.user.profile.surname }}</v-flex
          >
        </template>
        <template v-slot:item.status.name="{ item }">
          <span :class="getStatusColor(item.status.name)">
            {{ item.status.name }}
          </span>
        </template>
        <template v-slot:item.amount="{ item }">
          {{ formatCurrency(item.amount) }}
        </template>
      </v-data-table>
    </v-card>
  </S2SForm>
</template>

<script>
  import Vue from "vue";
  import { Utils } from "@/utils/";
  import { Paging } from "@/store/utils/";

  export default Vue.extend({
    name: "PaidOrders",

    data: function () {
      return {
        modal: false,
        dates: { fromDate: null, toDate: null },
        menu: false,
        statusFilters: [],

        headers: [
          { text: "ID#", value: "id", sortable: false },
          {
            text: "Date Created",
            value: "dateCreated",
            type: "date",
            sortable: false,
          },
          {
            text: "Customer Code",
            value: "organisation.code",
            sortable: false,
          },
          { text: "Customer", value: "organisation.name", sortable: false },
          { text: "Placed By", value: "user", sortable: false },
          { text: "Fulfillment", value: "deliveryType", sortable: false },
          { text: "Order Status", value: "status.name", sortable: false },
          { text: "Payment Status", value: "status.name", sortable: false },
          {
            text: "Total",
            value: "amount",
            type: "currency",
            class: "currency",
            sortable: false,
          },
        ],
      };
    },

    computed: {
      _pagination: {
        get() {
          return this.pagination;
        },
        set(value) {
          this.$store.dispatch("orders/paginate", value);
        },
      },
      orders: function () {
        return this.$store.state.orders.orders;
      },
      pagination: function () {
        return this.$store.state.orders.pagination;
      },
      totalItems: function () {
        return this.$store.state.orders.totalItems;
      },
      loading: function () {
        return this.$store.state.orders.loading;
      },
      orderStatussesPaid: function () {
        return this.$store.getters["orders/orderStatussesPaid"];
      },
    },

    watch: {
      pagination: function (newx, oldx) {
        if (this.orderStatussesPaid.length < 1) return;
        this.$store.dispatch("orders/fetchOrders");
      },
      statusFilters: function () {
        this.onFiltersChange();
      },
      orderStatussesPaid: function () {
        const orderStatusFilter =
          this.statusFilters.length > 0
            ? this.statusFilters
            : this.getOrderStatusNames();
        this.$store.dispatch("orders/setFilters", {
          orderstatus: orderStatusFilter,
        });
        this.$store.dispatch("orders/paginate", { ...this.pagination });
      },
      dates: function () {
        this.onFiltersChange();
      },
    },

    mounted: function () {
      this.$store.dispatch("orders/fetchOrderStatusses");
      const orderStatusFilter =
        this.statusFilters.length > 0
          ? this.statusFilters
          : this.getOrderStatusNames();
      this.$store.dispatch("orders/setFilters", {
        orderstatus: orderStatusFilter,
        fromDate: this.dates.fromDate,
        toDate: this.dates.toDate,
        paymentstatus: ["PAID"],
        salesOrder: true,
      });
    },

    methods: {
      getOrderStatusNames() {
        let orderFilters = [];
        for (let status of this.orderStatussesPaid) {
          orderFilters.push(status.name);
        }
        return orderFilters;
      },
      onFiltersChange() {
        // Set filter, reset paging, refetch orders
        const orderStatusFilter =
          this.statusFilters.length > 0
            ? this.statusFilters
            : this.getOrderStatusNames();
        this.$store.dispatch("orders/setFilters", {
          orderstatus: orderStatusFilter,
          fromDate: this.dates.fromDate,
          toDate: this.dates.toDate,
        });
        this.$store.dispatch("orders/paginate", {
          ...this.pagination,
          page: 1,
        });
      },
      onOrder(order) {
        this.$router.push({
          name: "view-order",
          params: { orderId: order.id },
        });
      },

      formatColumn(header, item) {
        return Utils.formatColumn(header, item);
      },
      formatCurrency(text) {
        return Utils.formatText(text, Utils.TextType.CURRENCY);
      },
      getStatusColor(status) {
        let statusColor = "";
        switch (status) {
          case "Pending":
            statusColor = "blue--text";
            break;

          case "Accepted":
          case "Completed":
            statusColor = "green--text";
            break;

          case "Ready":
            statusColor = "orange--text";
            break;

          case "Rejected":
          case "Cancelled":
            statusColor = "red--text";
            break;

          case "In Transit":
            statusColor = "grey--text";
            break;

          default:
            statusColor = "";
        }
        return statusColor;
      },
    },
  });
</script>

<style>
  .currency {
    min-width: 200px;
  }

  .grid-row {
    cursor: pointer;
  }
</style>
